import {
  EmailTemplateCustomer,
  EmailTemplateFailedPayment,
  EmailTemplateLoyaltyItem,
  EmailTemplateOoSItem,
  EmailTemplateSubscription,
  ICustomerEmailPasswordless,
  ICustomerEmailTheming,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { LoyaltyApi } from "../../interfaces/loyalty/api";
import { MailingAddress } from "../../shopifyGraphQL/api";
import { ICustomerRelationship } from "../CustomerRelationship";
import { ISetup } from "../Organization";
import { EmailTemplateMigration } from "./emails/MerchantEmails/Migrations/MigrationSummary";
import { tiersMock } from "../../interfaces/loyalty/mock";

export interface ICustomerEmailDummy {
  customer: EmailTemplateCustomer;
  subscription: EmailTemplateSubscription;
  note: string;
  to: MailingAddress;
  from: Pick<ICustomerRelationship, "firstName" | "lastName">;
  shippedOn: string;
  outOfStockItem: EmailTemplateOoSItem;
  theming: ICustomerEmailTheming;
  passwordless: ICustomerEmailPasswordless;
  failedPayment: EmailTemplateFailedPayment;
  referrals: {
    referrer: EmailTemplateCustomer;
    pointsEarned: number;
    pointBalance: number;
    referee: EmailTemplateCustomer;
    program: LoyaltyApi.Program.Type;
  };
  program: LoyaltyApi.Program.Type;
  accountCreationReminder: {
    pointsEarned: number;
  };
  bonusEarnedReminder: {
    pointsEarned: number;
    bonusPointsOrderNumber: number;
    customerBalance: number;
    currentMonth: string;
    loyaltyItems: EmailTemplateLoyaltyItem[];
  };
  otpRedemption: {
    discountCode: string;
    itemRedeemed: EmailTemplateLoyaltyItem;
  };
  rewardsReminder: {
    customerBalance: number;
    currentMonth: string;
    loyaltyItems: EmailTemplateLoyaltyItem[];
  };

  // TODO: combine all loyalty related variables into loyalty obj
  loyalty: {
    customerBalance: number;
    customerTierBalance?: number;
    currencySymbol: string;
    tiers?: {
      currentTier: LoyaltyApi.Tier.Type;
      nextTier: LoyaltyApi.Tier.Type | null;
    };
  };
  migration: EmailTemplateMigration;
  action: string;
  currency: string;
  orgName: string;
  currenciesCount: number;
  currencies: string;
}

export const dummyRewards: LoyaltyApi.Program.Type = {
  updatedDate: Date.now().toString(),
  id: "",
  status: "ENABLED",
  otpEnabled: false,
  tiersEnabled: false,
  events: [
    {
      type: "BILLING_SUCCESS",
      pointsEarned: 1,
    },
    {
      type: "ACCOUNT_CREATED",
      pointsEarned: 50,
    },
  ],
  currencies: [],
  organizationId: "",
  variants: [
    {
      costInPoints: 100,
      productId: "0364140901",
    } as any,
  ],
  description:
    "Our loyalty program is meant to reward our most loyal customers. We value you, and want you to be rewarded for every subscription purchase.",
  rewardUnitNameSingular: "point",
  rewardUnitNamePlural: "points",
  tiers: [],
  incentives: [
    {
      rewardType: "INCENTIVE",
      id: "e00b2e00-7078-46fd-be14-4e8739d20842",
      color: "#fffaab",
      costInPoints: 200,
      priceRule: {
        type: "fixed_amount",
        value: "-20.0",
      },
      hidden: false,
      subscriberOnly: false,
      tiers: [],
    },
    {
      rewardType: "INCENTIVE",
      id: "b8ff9f67-5812-4a07-8d60-acf294a0bb71",
      color: "#e7f9f9",
      costInPoints: 100,
      priceRule: {
        type: "fixed_amount",
        value: "-10.0",
      },
      hidden: false,
      subscriberOnly: false,
      tiers: [],
    },
    {
      rewardType: "INCENTIVE",
      id: "6884128a-421e-4125-9068-28ddd5542931",
      color: "#89f798",
      costInPoints: 300,
      priceRule: {
        type: "fixed_amount",
        value: "-30.0",
      },
      hidden: false,
      subscriberOnly: false,
      tiers: [],
    },
  ],
  bonus: {
    every: 10,
    givePoints: 500,
  },
  referralProgram: {
    description:
      "Get a 1,000 points each time a friend uses your referral link on their first subscription purchase. Bonus—they'll get 10% off their first order, too!",
    name: "Give 10%, get 1,000 points",
    pointsInReward: 1000,
    generatedDiscountCodes: [],
    priceRule: {
      id: "00000909",
      type: "percentage",
      value: "-11.0",
    },
  },
  rewardSettings: {
    limitUse: 0,
    stackableDiscountCodes: false,
  },
};

export const dummyLoyaltyItems: EmailTemplateLoyaltyItem[] = [
  {
    itemType: "product",
    costInPoints: 100,
    productName: "My Item A",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
  {
    itemType: "product",
    costInPoints: 120,
    productName: "My Item B",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
  {
    itemType: "incentive",
    costInPoints: 150,
    productName: "5% off your next order",
    imageUrl: "",
    imageText: "5% off",
    imageColor: "#E0FFEB",
  },
  {
    itemType: "product",
    costInPoints: 400,
    productName: "My Item D",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
  {
    itemType: "product",
    costInPoints: 600,
    productName: "My Item E",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
  {
    itemType: "incentive",
    costInPoints: 150,
    productName: "$2 off your next order",
    imageUrl: "",
    imageText: "$2 off",
    imageColor: "#E6E0FF",
  },
  {
    itemType: "product",
    costInPoints: 1000,
    productName: "My Item G",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
  {
    itemType: "product",
    costInPoints: 1200,
    productName: "My Item H",
    imageUrl: "https://app.smartrr.com/favicon-256x256.png",
    imageText: "",
    imageColor: "",
  },
];

export const dummyEmailData: ICustomerEmailDummy = {
  subscription: {
    id: "1423540418",
    nextBillingDate: `November 10, ${new Date().getFullYear()}`,
    lines: [
      {
        id: "0364140974",
        basePrice: "$19.99",
        isAddOn: false,
        priceAfterDiscounts: "$5.00",
        quantity: 2,
        product: {
          id: "8754140053",
          name: "English Breakfast",
        },
        variant: {
          id: "4356175053",
          name: "Default Title",
          image:
            "https://cdn.shopify.com/s/files/1/0642/0375/1683/files/trent-erwin-fKImVjUwkXc-unsplash_1.png?v=1670442340",
        },
      },
      {
        id: "0364140901",
        basePrice: "$39.99",
        isAddOn: true,
        priceAfterDiscounts: "$35.95",
        quantity: 1,
        product: {
          id: "8754140053",
          name: "Assam Black",
        },
        variant: {
          id: "4356175053",
          name: "3 Pack",
          image:
            "https://cdn.shopify.com/s/files/1/0642/0375/1683/files/tina-witherspoon-po_OaHERAV8-unsplash_1.png?v=1670442340",
        },
      },
      {
        id: "0364140901",
        basePrice: "$24.99",
        isAddOn: true,
        priceAfterDiscounts: "$24.99",
        quantity: 1,
        product: {
          id: "8754140053",
          name: "Rooibos",
        },
        variant: {
          id: "4356175053",
          name: "6 Pack",
          image:
            "https://cdn.shopify.com/s/files/1/0642/0375/1683/files/jakub-dziubak-XtUd5SiX464-unsplash_1.png?v=1670442745",
        },
      },
    ],
    currency: "USD",
    totalDiscount: "$79.80",
    totalDiscountForUpcomingOrder: "$79.80",
    totalEstimatedNet: "$333.69",
    totalFromLineItems: "$399.00",
    totalLineItemsDiscount: "$79.80",
    totalShipping: "$14.49",
    totalShippingDiscount: "$0.00",
    deliveryDate: `November 13, ${new Date().getFullYear()}`,
    unpauseDate: `November 9, ${new Date().getFullYear()}`,
    daysTillUnpause: 7,
    daysTillDelivery: 3,
    cardType: "Visa",
    orderNote: "This order is a gift for my dad, can it be wrapped with gift wrapping? Thanks!",
  },
  customer: {
    id: "5283630743746",
    email: "test@smartrr.com",
    firstName: "John",
    lastName: "Smith",
    phone: "7189206453",
  },
  failedPayment: {
    reason: "Your card was declined for making repeated attempts too frequently or exceeding its amount limit",
  },
  note: "Test Gift Note",
  to: {
    address1: "135 Madison Ave",
    address2: "334 5th Ave",
    city: "New York",
    company: "Smartrr",
    firstName: "John",
    lastName: "Smith",
    phone: "2129982321",
    province: "NY",
    provinceCode: "212",
    zip: "10035",
    id: "",
    formatted: [""],
    coordinatesValidated: true,
  },
  from: {
    firstName: "Bill",
    lastName: "Smith",
  },
  action: "",
  shippedOn: `November 11, ${new Date().getFullYear()}`,
  outOfStockItem: {
    id: "1423540418",
    name: "Bev Gris",
    variantName: "24 Pack",
    unavailableReason: "out_of_stock",
    image:
      "https://cdn.shopify.com/s/files/1/0642/0375/1683/products/bev-gris-740829_590x_54a1589b-1f96-4147-a49f-9882fee1e085.png?v=1651850602",
  },
  theming: createDefaultEmailThemingValues(),
  passwordless: {
    expirationLength: 20,
    loginUrl: "",
    oneTimeCode: "00000",
  },
  referrals: {
    referee: {
      id: "5283630743746",
      email: "test@smartrr.com",
      firstName: "Bill",
      lastName: "Smith",
      phone: "7189206453",
    },
    referrer: {
      id: "5283630743746",
      email: "test@smartrr.com",
      firstName: "John",
      lastName: "Smith",
      phone: "7189206453",
    },
    pointBalance: 100,
    pointsEarned: 20,
    program: dummyRewards,
  },
  program: dummyRewards,
  accountCreationReminder: {
    pointsEarned: 10,
  },
  bonusEarnedReminder: {
    pointsEarned: 10,
    bonusPointsOrderNumber: 5,
    customerBalance: 50,
    currentMonth: "May",
    loyaltyItems: dummyLoyaltyItems,
  },
  otpRedemption: {
    discountCode: "TEST-DISCOUNTCODE",
    itemRedeemed: {
      itemType: "incentive",
      costInPoints: 150,
      productName: "$2 off your next order",
      imageUrl: "",
      imageText: "$2 off",
      imageColor: "#E6E0FF",
    },
  },
  rewardsReminder: {
    customerBalance: 50,
    currentMonth: "May",
    loyaltyItems: dummyLoyaltyItems,
  },
  migration: {
    fileName: "snacks_migration_20231120.csv",
    completionDate: "April 12th, 2024 - 18:38:41 UTC",
    successNum: 418,
    failedNum: 0,
    shopifyDomain: "fakeDomain.myshopify.com",
  },
  loyalty: {
    customerBalance: 50,
    customerTierBalance: 50,
    currencySymbol: "$",
    tiers: {
      currentTier: tiersMock[0],
      nextTier: tiersMock[1],
    },
  },
  currency: "USD",
  orgName: "Snacks Inc",
  currencies: "USD, BRL",
  currenciesCount: 2,
};

// Generating default / dummy theming values
export function createDefaultEmailThemingValues(setup?: ISetup): ICustomerEmailTheming {
  // Email defaults are disabled for existing shop but enabled for new shops post-setup
  const isExistingShop = setup && Boolean(setup.selectedSellingPlanId);

  return {
    logo: "",
    logoWidth: 180,
    useHeaderAndFooter: false,
    useStyling: !isExistingShop,
    colors: {
      backgroundColor: "#FFFFFF",
      primaryTextColor: "#202223",
      secondaryTextColor: "#7A7A7A",
      buttonBackgroundColor: "#2E2E2E",
      buttonTextColor: "#FFFFFF",
    },
  };
}
