import { ProductUnavailableReason } from "@smartrr/shared/entities/CustomerEmailConfig";
import { dummyEmailData, ICustomerEmailDummy } from "@smartrr/shared/entities/CustomerEmailConfig/mocks";
import {
  ICustomerEmailConfig,
  ICustomerEmailTheming,
  IFormValues,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { toViewDate, toViewLongDate } from "@smartrr/shared/utils/renderViewDate";
import { DateTime } from "luxon";
import { useDefaultEmailConfig } from "..";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { getDummyLineItems } from "../../utils";
import { ThemingStoreAccess } from "@vendor-app/app/AdminRoute/AdminThemingRoute/store";
import { purchasableSnowboardOxygen } from "@smartrr/shared/tests/mocks/purchasableMocks/basic";

export const useEmailContext = (formValues: IFormValues): ICustomerEmailDummy => {
  const defaultEmailConfig = useDefaultEmailConfig();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const activeOrg = useActiveOrganizationSelector();
  const { currentThemeValues } = ThemingStoreAccess.useThemeProperties();

  const dummyLines = getDummyLineItems(purchasables.length ? purchasables : [purchasableSnowboardOxygen]);
  const locale = currentThemeValues?.translationOverrides?.activeLanguage;

  // Determine email theming and delivery delay
  const emailTheming = (formValues as ICustomerEmailTheming)?.useStyling
    ? (formValues as ICustomerEmailTheming)
    : ((formValues as ICustomerEmailConfig)?.emailTheming ?? defaultEmailConfig.emailTheming);

  const upcomingOrderEmailDeliveryDelay = (formValues as ICustomerEmailTheming)?.useStyling
    ? defaultEmailConfig.upcomingOrderEmailDeliveryDelay
    : ((formValues as ICustomerEmailConfig)?.upcomingOrderEmailDeliveryDelay ??
      defaultEmailConfig.upcomingOrderEmailDeliveryDelay);

  // Calculate dates
  const todayPlusDelayOffset = DateTime.fromJSDate(new Date(dummyEmailData.subscription.nextBillingDate)).plus({
    days: Math.max(upcomingOrderEmailDeliveryDelay, 1),
  });

  // Construct and return the email context
  return {
    ...dummyEmailData,
    shippedOn: toViewDate(dummyEmailData.shippedOn, {
      month: "long",
      day: "numeric",
      year: "numeric",
      locale,
    }),
    subscription: {
      ...dummyEmailData.subscription,
      lines: dummyLines,
      daysTillDelivery: upcomingOrderEmailDeliveryDelay,
      unpauseDate: toViewDate(dummyEmailData.subscription.unpauseDate, {
        month: "long",
        day: "numeric",
        year: "numeric",
        locale,
      }),
      deliveryDate: toViewLongDate(todayPlusDelayOffset, locale),
      nextBillingDate: toViewDate(dummyEmailData.subscription.nextBillingDate, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        locale,
      }),
    },
    outOfStockItem: {
      ...dummyLines[0].product,
      variantName:
        purchasables.find(p => p.id === dummyLines[0].product.id)?.vnts?.[0].purchasableVariantName ?? "",
      unavailableReason: ProductUnavailableReason.OUT_OF_STOCK,
      image: dummyLines[0].variant.image,
    },
    theming: emailTheming,
    passwordless: {
      ...dummyEmailData.passwordless,
      loginUrl: activeOrg ? `https://${activeOrg.myShopifyDomain}/account/login` : "",
    },
  };
};
